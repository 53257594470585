var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "사업소개",
      "text": "금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다.",
      "image": "/images/about/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "subtitle": "Vison",
      "title": "비전"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto pa-16 pa-md-40 rounded-xl rounded-lg-pill",
    attrs: {
      "outlined": "",
      "max-width": "1020"
    }
  }, [_c('v-row', [_vm._l(_vm.visions, function (item) {
    return [_c('v-col', {
      key: item.text,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('v-sheet', {
      staticClass: "pa-24 mx-auto overflow-visible",
      attrs: {
        "max-width": "298",
        "rounded": "circle",
        "color": item.color
      }
    }, [_c('v-responsive', {
      staticClass: "overflow-visible",
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-sheet', {
      staticClass: "font-size-20 text-pre-wrap text-center line-height-16 box-shadow",
      attrs: {
        "rounded": "circle"
      }
    }, [_c('v-responsive', {
      staticClass: "d-flex justify-center align-center",
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-row', {
      staticClass: "row--lg ma-0"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5",
    attrs: {
      "title": "핵심가치",
      "subtitle": "Core Value"
    }
  }, [_c('v-card', {
    staticClass: "pa-24 pa-md-42 tit-wrap tit-wrap--lg",
    attrs: {
      "rounded": "pill",
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit tit--sm font-weight-bold text-center"
  }, [_vm._v("슬로건")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "406",
      "width": "100%",
      "src": "/images/about/business/core-value.svg",
      "alt": "사람과 사람으로 연결된 하나의 이음공동체 금산"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "core-value-image"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(3, function (index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "src": `/images/about/business/value-${index}.jpg`
      }
    })], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    attrs: {
      "title": "활성화체계",
      "subtitle": "Promotion System"
    }
  }, [_c('div', {
    staticClass: "system-title pt-28 pt-md-40 pb-24 pb-md-32"
  }, [_vm._v("마을에서 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("새로운")]), _vm._v(" 나의 역할을 찾다. "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("新")]), _vm._v("활력 금산살이 3⁺")]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.systems, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('promotion-system', {
      attrs: {
        "index": index,
        "item": item
      }
    })], 1)];
  })], 2)], 1), _c('page-section', [_c('div', {
    staticClass: "system-title"
  }, [_vm._v("종합구상도")]), _c('v-img', {
    attrs: {
      "src": "/images/about/business/plan.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('div', {
    staticClass: "system-title mb-24 mb-md-32"
  }, [_vm._v("단계별 주민 공동체 활동지원으로 지역 내 주민조직의 지속성장 기대")]), _c('v-img', {
    attrs: {
      "src": "/images/about/business/step.jpg"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }