<template>
    <client-page>
        <sub-visual sh="금산마을 이야기" text="금산군에 있는 260개 마을의 발전을 위한 기초자료를 구축하고자 마을자원과 현황을 조사하고, 주민의 사업수요를 들어보았습니다." image="/images/story/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <v-row class="row--xl">
                <v-col cols="12" md="8">
                    <map-image v-bind="{ parent }" />
                </v-col>
                <v-col cols="12" md="4">
                    <div class="tit mb-18 mb-md-24 font-weight-bold">{{ STORY_REGION_TYPES[parent].text }}</div>
                    <v-divider class="v-divider--dark mb-32 mb-md-40"></v-divider>
                    <v-row class="row--sm">
                        <v-col v-for="(region, index) in regionItems" :key="index" cols="6">
                            <map-dialog v-bind="{ region }">
                                <template #activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" block outlined x-large class="map-button">{{ region.name }}</v-btn>
                                </template>
                            </map-dialog>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-card flat img="/images/map/e-book.jpg" rounded="xl" class="e-book-banner mt-60 mt-md-60">
                <v-row justify="center" align="center" class="h-100">
                    <v-col cols="auto">
                        <div class="font-size-24 font-size-md-28 primary--text text--darken-4 font-weight-bold">260 마을 스토리 E-Book 전체보기</div>
                    </v-col>
                    <v-col cols="auto">
                        <v-img max-width="24" src="/images/icon/icon-double-right.svg" />
                    </v-col>
                </v-row>
            </v-card>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { STORY_REGION_TYPES } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MapImage from "@/components/client/map/map-image.vue";
import MapDialog from "@/components/client/map/map-dialog.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        MapImage,
        MapDialog,
    },
    data() {
        return {
            parent: this.$route.query?.parent || STORY_REGION_TYPES.GEUMSAN.value,
            regions: [],
            regionItems: [],
            STORY_REGION_TYPES,
        };
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            let { regions } = await api.v1.story.region.gets({});

            this.regions = regions;
            this.setRegionItems();
        },
        setRegionItems() {
            // this.regionItems = this.regions.filter(({ parent }) => parent === this.parent).sort((a, b) => a.name.localeCompare(b.name));
            this.regionItems = this.regions.filter(({ parent }) => parent === this.parent);
        },
    },
    watch: {
        "$route.query"() {
            this.parent = this.$route.query.parent;
            this.setRegionItems();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-divider {
    &--dark {
        border-color: var(--v-grey-darken4);
    }
}

.map-button {
    border-color: var(--v-grey-lighten3);
    border-radius: 8px;
    &::before {
        display: none;
    }
    &:hover {
        &::v-deep {
            .v-btn__content {
                color: var(--v-primary-base);
            }
        }
        border-color: var(--v-primary-base);
    }
}
.e-book-banner {
    height: 200px;
    background-position: bottom left !important;
}
@media (min-width: 768px) {
    .e-book-banner {
        background-position: center !important;
    }
}
</style>
